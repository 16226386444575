<template>
  <v-layout column>
    <v-layout
      v-if="isAnyHeaderActionActive"
      row
      wrap
      justify-end
      align-center
      class="pull-to-top"
      :style="$vuetify.breakpoint.xs ? 'margin-top: 0px;' : ''"
    >
      <v-menu>
        <v-tooltip slot="activator" top>
          <v-btn
            v-if="enableSavedViews"
            id="data-table-button-saved-views"
            slot="activator"
            class="btn-secondaryaction"
          >
            <span :class="currentViewSettings.isDirty ? '' : 'blue-text'">
              {{ currentViewSettings.name || 'Saved Views' }}
            </span>
            <v-icon v-if="currentViewSettings.isDirty" style="font-size: 14px">
              keyboard_arrow_down
            </v-icon>
            <v-icon
              v-if="!currentViewSettings.isDirty"
              style="font-size: 14px"
              color="primary"
            >
              keyboard_arrow_down
            </v-icon>
          </v-btn>
          Saved Views
        </v-tooltip>

        <v-list>
          <v-list-tile-sub-title class="padded-menu-item">
            <h6>Saved Views</h6>
          </v-list-tile-sub-title>
          <v-divider light />
          <v-list-tile-content v-if="savedViews.length === 0">
            <v-list-tile-title class="padded-menu-item">
              No Saved Views
            </v-list-tile-title>
          </v-list-tile-content>
          <div
            v-for="(view, key) in savedViews"
            :key="`padded-menu-${key}-page-${pagination.page}`"
          >
            <v-layout class="padded-menu-item">
              <a
                :id="`data-table-button-saved-views-${key}-load`"
                @click="loadSavedView(view)"
              >
                {{ view.name }}
              </a>
              <v-spacer style="min-width: 8px" />
              <v-icon
                v-if="!view.shared && isAdmin"
                :id="`data-table-button-saved-views-${key}-share`"
                color="primary"
                small
                @click="shareView(view)"
              >
                share
              </v-icon>
              <v-icon
                :id="`data-table-button-saved-views-${key}-star`"
                :color="favoriteView.favoriteId === view._id ? 'warning' : ''"
                small
                @click="setFavorite(view)"
              >
                star
              </v-icon>
              <v-icon
                v-if="!view.shared || (view.shared && isAdmin)"
                :id="`data-table-button-saved-views-${key}-edit`"
                color="primary"
                small
                @click="() => editViewName(view)"
              >
                edit
              </v-icon>

              <v-icon
                v-if="!view.shared || (view.shared && isAdmin)"
                :id="`data-table-button-saved-views-${key}-remove`"
                color="error"
                small
                @click="() => removeView(view)"
              >
                clear
              </v-icon>
            </v-layout>
          </div>
          <v-divider />
          <div>
            <div class="padded-menu-item">
              <a id="data-table-button-add-new" @click="createNewView">
                <v-icon small>add</v-icon>
                <span>Add New</span>
              </a>
            </div>
            <div class="padded-menu-item">
              <a id="data-table-button-clear-all" @click="clearView">
                <v-icon small>clear</v-icon>
                <span>Clear All Filters</span>
              </a>
            </div>
            <div id="data-table-button-refresh-view" class="padded-menu-item">
              <a @click="reloadCurrentView">
                <v-icon small>autorenew</v-icon>
                <span>Refresh View</span>
              </a>
            </div>
            <div
              v-if="currentViewSettings.isDirty && currentViewSettings.name"
              class="padded-menu-item"
            >
              <a
                id="data-table-button-view-save"
                @click="() => saveViewSettings(currentViewSettings)"
              >
                <v-icon small>save</v-icon>
                Save "{{ currentViewSettings.name }}"
              </a>
            </div>
            <div
              v-if="currentViewSettings.isDirty && !currentViewSettings.name"
              class="padded-menu-item"
            >
              <a
                id="data-table-button-view-edit"
                @click="() => editViewName(currentViewSettings, true)"
              >
                <v-icon small>save</v-icon>
                Save
              </a>
            </div>
            <br />
          </div>
        </v-list>
      </v-menu>

      <v-bottom-sheet v-model="sheetNameEdit">
        <v-card>
          <div>
            <div />
            <div>
              <v-text-field
                id="data-table-text-field-view-edit"
                v-model="editViewObject.name"
                type="text"
                label="Enter view name"
                placeholder="Enter view name"
                solo
                flat
                append-icon="save"
                @click:append="() => saveViewSettings(editViewObject)"
              />
            </div>
          </div>
        </v-card>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="sheetViewRemove">
        <v-card>
          <div>
            <div />
            <div class="column align-middle">
              Are you sure you want to remove
              <strong>
                {{ removeViewObject ? removeViewObject.name : '' }}
              </strong>
              ?
              <v-btn
                id="data-table-button-remove-view-cancel"
                class="btn-secondaryaction"
                @click="sheetViewRemove = false"
              >
                Cancel
              </v-btn>
              <v-btn
                id="data-table-button-remove-view-submit"
                class="btn-primaryaction bold"
                @click="() => confirmRemove(removeViewObject)"
              >
                Yes, Remove It
              </v-btn>
            </div>
            <div />
          </div>
        </v-card>
      </v-bottom-sheet>
      <v-bottom-sheet v-model="sheetViewShare">
        <v-card>
          <div>
            <div />
            <div class="column align-middle">
              Would you like to share
              <strong>{{ shareViewObject ? shareViewObject.name : '' }}</strong>
              with everyone in your company?
              <v-btn
                id="data-table-button-share-view-cancel"
                class="btn-secondaryaction"
                @click="sheetViewShare = false"
              >
                Cancel
              </v-btn>
              <v-btn
                id="data-table-button-share-view-submit"
                class="btn-primaryaction"
                @click="() => confirmShareView(shareViewObject)"
              >
                Yes, Share It
              </v-btn>
            </div>
            <div />
          </div>
        </v-card>
      </v-bottom-sheet>

      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            v-if="enableExport"
            id="data-table-export-button-submit"
            class="btn-secondaryaction"
            @click="exportTable"
            v-on="on"
          >
            Export
          </v-btn>
        </template>
        Export
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            v-if="enableImport"
            id="data-table-import-button-submit"
            class="btn-secondaryaction"
            @click="importTable"
            v-on="on"
          >
            Import
          </v-btn>
        </template>
        Import
      </v-tooltip>
      <slot v-if="$slots.actionDropDown" name="actionDropDown" />
      <v-bottom-sheet v-model="sheetColumns">
        <v-tooltip
          slot="activator"
          absolute
          content-class="columnConfigTooltip"
          attach="#columnConfig"
        >
          <v-btn
            v-if="enableColumnConfig"
            id="columnConfig"
            slot="activator"
            class="btn-secondaryaction"
          >
            Columns
          </v-btn>
          Column Visibility
        </v-tooltip>
        <v-card>
          <v-layout row>
            <v-flex xs6 />
            <v-flex xs6>
              <v-layout class="bottom-layout">
                <v-flex xs1>
                  <br />
                  <br />
                  <div>
                    <button
                      id="data-table-sort-active-column-button-up"
                      class="button float-right"
                      @click="() => sortActiveColumn('up')"
                    >
                      <CRIcon style="margin-bottom: -4px">arrow_upward</CRIcon>
                    </button>
                  </div>
                  <br />
                  <br />
                  <div>
                    <button
                      id="data-table-sort-active-column-button-down"
                      class="button float-right"
                      @click="() => sortActiveColumn('down')"
                    >
                      <CRIcon style="margin-bottom: -4px">
                        arrow_downward
                      </CRIcon>
                    </button>
                  </div>
                </v-flex>
                <v-flex xs5>
                  <label>Shown</label>
                  <div class="multiselect">
                    <Draggable
                      :list="shownColumns"
                      ghost-class="ghost"
                      group="columns"
                      @end="() => sortHiddenColumns()"
                    >
                      <div
                        v-for="(column, key) in shownColumns"
                        :id="`data-table-multiselect-shown-button-col-${key}`"
                        :key="`multiSelect-${key}-col-${column._t_id}`"
                        :class="
                          activeColumn && column._t_id === activeColumn._t_id
                            ? 'ms-item ms-active'
                            : 'ms-item'
                        "
                        :value="column._t_id"
                        :selected="
                          activeColumn && column._t_id === activeColumn._t_id
                        "
                        @click="() => setShownActive(column)"
                        @dblclick="() => moveColumn(column, 'hidden')"
                      >
                        {{ column.text }}
                      </div>
                    </Draggable>
                  </div>
                  <div class="mt-2" style="display: flex; align-items: center">
                    <div style="flex-shrink: 1">
                      <CRIcon
                        color="primary"
                        small
                        view-box="0 0 24 24"
                        height="24"
                        width="24"
                        class="mr-2"
                      >
                        info
                      </CRIcon>
                    </div>
                    <div class="font-12" style="flex-grow: 1">
                      Can now drag / drop columns!
                    </div>
                  </div>
                </v-flex>
                <v-flex v-if="hiddenColumnsFiltered" xs5>
                  <label>Hidden</label>
                  <div class="multiselect">
                    <Draggable
                      :list="hiddenColumns"
                      ghost-class="ghost"
                      group="columns"
                      @end="() => sortHiddenColumns()"
                    >
                      <div
                        v-for="(column, key) in hiddenColumns"
                        :id="`data-table-multiselect-hidden-button-col-${key}`"
                        :key="`multiselect-hidden-col-${key}-${column._t_id}`"
                        class="ms-item"
                        :value="column._t_id"
                        :selected="
                          activeColumn && column._t_id === activeColumn._t_id
                        "
                        @dblclick="() => moveColumn(column, 'shown')"
                      >
                        {{ column.text }}
                      </div>
                    </Draggable>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-bottom-sheet>

      <div v-if="addNewEnabled">
        <v-btn
          id="data-table-top-add-new-button"
          class="btn-primaryaction add-new"
          @click="addNewHandler(list)"
        >
          {{ addNewButtonLabel }}
        </v-btn>
      </div>

      <div
        v-if="enableSelectableRows"
        class="multi-select-actions"
        style="margin-right: -10px; display: flex; flex-direction: row"
      >
        <slot v-if="showMultiSelectActions" name="multiSelectActions" />
        <v-btn
          id="data-table-button-multiselect-cancel-and-select"
          class="btn-primaryaction"
          depressed
          @click="
            showMultiSelectActions ? endMultiSelect() : beginMultiSelect()
          "
        >
          {{ showMultiSelectActions ? 'Cancel' : 'Select' }}
        </v-btn>
      </div>
    </v-layout>
    <v-layout column>
      <v-layout
        v-show="areStatusFiltersShowing"
        v-if="categoryFilters.length > 1"
        row
        :wrap="$vuetify.breakpoint.smOrLower"
        class="status-filters"
      >
        <v-flex
          v-for="(category, categoryIndex) in soloCategoryFilters"
          :key="categoryIndex"
          class="status-filter-select"
          transition="scale-transition"
        >
          <h3>{{ category.text }}</h3>
          <v-layout v-if="category.autocomplete">
            <v-autocomplete
              :id="`solo-category-selector-autocomplete-category-select-${categoryIndex}`"
              class="margin-x-4"
              :value="categoryAutocompleteSelected(category)"
              item-value="categoryValue"
              item-text="text"
              solo
              flat
              :items="category.predefined.controls"
              :menu-props="{ closeOnContentClick: true }"
              return-object
              clearable
              hide-details
              append-icon="keyboard_arrow_down"
              :placeholder="category.text"
              @click:clear.stop="
                setCategoryFilter(category, {
                  ...categoryAutocompleteSelected(category),
                  value: undefined,
                })
              "
              @input="setCategoryFilter(category, $event)"
            />
          </v-layout>
          <v-layout v-else wrap justify-center>
            <v-chip
              v-for="(control, controlIndex) in category.predefined.controls"
              :id="`data-table-button-category-${categoryIndex}-control-${controlIndex}`"
              :key="`data-table-button-category-${categoryIndex}-control-${controlIndex}`"
              class="elevation-0"
              small
              :color="categoryChipSelected(category, control) ? 'primary' : ''"
              :text-color="
                categoryChipSelected(category, control) ? 'white' : ''
              "
              :outline="!categoryChipSelected(category, control)"
              @click="() => setCategoryFilter(category, control)"
            >
              {{ control.text }}
            </v-chip>
          </v-layout>
        </v-flex>

        <v-flex
          v-for="(group, groupIndex) in groupedCategoryFilters"
          :key="`data-table-button-group-${groupIndex}`"
          class="status-filter-select"
          transition="scale-transition"
        >
          <h3>{{ group[0].text }}</h3>

          <v-layout wrap justify-center>
            <template v-for="(category, categoryIndex) in group">
              <v-autocomplete
                v-if="category.autocomplete"
                :id="`group-category-selector-autocomplete-category-select-${categoryIndex}`"
                :key="`group-category-selector-autocomplete-category-select-${categoryIndex}`"
                class="margin-x-4"
                :value="categoryAutocompleteSelected(category)"
                item-value="categoryValue"
                item-text="text"
                solo
                flat
                :items="category.predefined.controls"
                :menu-props="{ closeOnContentClick: true }"
                return-object
                clearable
                hide-details
                append-icon="keyboard_arrow_down"
                :placeholder="category.text"
                @click:clear.stop="
                  setCategoryFilter(category, {
                    ...categoryAutocompleteSelected(category),
                    value: undefined,
                  })
                "
                @input="setCategoryFilter(category, $event)"
              />
              <div
                v-else
                :key="`non-autocomplete-group-category-${categoryIndex}`"
              >
                <template
                  v-for="(control, controlIndex) in category.predefined
                    .controls"
                >
                  <v-chip
                    :id="`data-table-button-category-${categoryIndex}-control-${controlIndex}`"
                    :key="`${categoryIndex}-${controlIndex}`"
                    class="elevation-0"
                    small
                    :color="
                      categoryChipSelected(category, control) ? 'primary' : ''
                    "
                    :text-color="
                      categoryChipSelected(category, control) ? 'white' : ''
                    "
                    :outline="!categoryChipSelected(category, control)"
                    @click="() => setCategoryFilter(category, control)"
                  >
                    {{ control.text }}
                  </v-chip>
                </template>
              </div>
            </template>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout
        v-show="areStatusFiltersShowing"
        v-else
        row
        :wrap="$vuetify.breakpoint.smOrLower"
        justify-start
        class="status-filters"
      >
        <v-flex
          v-for="(category, categoryIndex) in categoryFilters"
          :key="`data-table-button-category-${categoryIndex}`"
          style="max-width: 33%"
          class="status-filter-select"
          transition="scale-transition"
        >
          <h3>{{ category.text }}</h3>
          <v-layout wrap justify-center>
            <v-chip
              v-for="(control, controlIndex) in category.predefined.controls"
              :id="`data-table-button-status-filter-${categoryIndex}-control-${controlIndex}`"
              :key="`data-table-button-category-${categoryIndex}-control-${controlIndex}`"
              class="elevation-0"
              small
              :color="categoryChipSelected(category, control) ? 'primary' : ''"
              :text-color="
                categoryChipSelected(category, control) ? 'white' : ''
              "
              :outline="!categoryChipSelected(category, control)"
              @click="() => setCategoryFilter(category, control)"
            >
              {{ control.text }}
            </v-chip>
          </v-layout>
        </v-flex>
      </v-layout>

      <div
        v-show="isAnyFilterActive"
        v-if="enableFilterRow"
        :class="`filter-tags ${
          !(getActiveFilter && !getActiveFilter.hideOnFilterBar)
            ? 'filter-tags--hidden'
            : ''
        }`"
      >
        <v-layout row>
          <v-tabs
            :value="
              filters
                .filter((f) => !f.hideOnFilterBar)
                .findIndex((filter) => filter.column._t_id === selectedFilter)
            "
          >
            <v-tab
              v-for="(filter, key) in filters.filter((f) => !f.hideOnFilterBar)"
              :id="`data-table-tab-filter-detail-${key}`"
              :key="`data-table-tab-filter-detail-${key}`"
              v-model="closableChips[filter.column.prop]"
              slider-color="primary"
              @click="showFilterDetail(filter)"
              @close="(value) => chipChange(value, filter)"
            >
              {{ filter.column.text }}
              {{
                filter.value !== undefined
                  ? renderFilterValue(
                      filter.value,
                      filter.column.customFilterTabDisplay
                    )
                  : ''
              }}
              <v-btn
                :id="`data-table-tab-filter-detail-${key}-close`"
                icon
                small
                @click="() => unsetFilter(filter)"
              >
                <v-icon small>close</v-icon>
              </v-btn>
            </v-tab>
          </v-tabs>
        </v-layout>
      </div>

      <div
        v-if="getActiveFilter && !getActiveFilter.hideOnFilterBar"
        class="filter-panel"
      >
        <div class="xs 12 sm12 md6 lg6 xl6">
          <v-text-field
            v-if="
              getActiveFilter &&
              ['number', 'text'].includes(getActiveFilter.column.type) &&
              !getActiveFilter.column.predefined
            "
            :id="
              getActiveFilter ? `filter-${getActiveFilter.column._t_id}` : ''
            "
            :placeholder="getActiveFilter.column.text"
            solo
            flat
            :type="getActiveFilter.column.type"
            :value="getActiveFilter ? getActiveFilter.value : ''"
            autocomplete="off"
            @input="updateFilterCriteria"
          />
          <!-- TODO: Come back here and finish this.        :-->
          <div v-if="getActiveFilter && getActiveFilter.column.predefined">
            <div>
              <v-layout
                v-if="
                  getActiveFilter.column.predefined.length > 1 ||
                  getActiveFilter.column.predefined[0].checkbox
                "
                row
                class="tag-list"
              >
                <template
                  v-for="(predefined, key) in getActiveFilter.column.predefined"
                >
                  <v-checkbox
                    v-show="predefined.checkbox"
                    :id="`data-table-filter-panel-checkbox-${key}`"
                    :key="`data-table-filter-panel-checkbox-${key}`"
                    :value="getActiveFilter.value"
                    :label="predefined.text"
                    @click.native="
                      () =>
                        predefined.withValue
                          ? selectPredefinedWithValue(
                              getActiveFilter.column,
                              predefined
                            )
                          : selectPredefined(predefined)
                    "
                  />
                  <v-chip
                    v-if="!predefined.checkbox"
                    :id="`data-table-filter-panel-chip-${key}`"
                    :key="`data-table-filter-panel-chip-${key}`"
                    class="elevation-0"
                    :class="
                      predefined.id &&
                      getActiveFilter.selectedPredefined &&
                      predefined.id === getActiveFilter.selectedPredefined.id
                        ? 'active-predefined'
                        : ''
                    "
                    @click.native="
                      () =>
                        predefined.withValue
                          ? selectPredefinedWithValue(
                              getActiveFilter.column,
                              predefined
                            )
                          : selectPredefined(predefined)
                    "
                  >
                    {{ predefined.text }}
                  </v-chip>
                </template>
              </v-layout>
            </div>
            <v-layout v-if="getActiveFilter.selectedPredefined" column>
              <v-layout
                v-if="
                  getActiveFilter.selectedPredefined.controlType ===
                  'default-repeat'
                "
                row
              >
                <v-flex
                  v-for="(control, controlIndex) in getActiveFilter
                    .selectedPredefined.controls"
                  :key="`data-table-control-${controlIndex}`"
                  class="filter-control"
                >
                  <v-menu
                    v-if="
                      getActiveFilter &&
                      getActiveFilter.column.type === 'date' &&
                      !getControlComponent(getActiveFilter.column, control)
                    "
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                  >
                    <template #activator="calendar">
                      <v-text-field
                        :id="`data-table-filter-panel-text-field-${controlIndex}`"
                        :value="control.displayValue"
                        :label="control.text"
                        :placeholder="control.text"
                        solo
                        flat
                        hide-details
                        prepend-icon="event"
                        readonly
                        v-on="calendar.on"
                      />
                    </template>
                    <v-date-picker
                      :id="`data-table-filter-panel-date-picker-${controlIndex}`"
                      :value="control.displayValue"
                      solo
                      flat
                      no-title
                      @input="(e) => handleDatePickerInput(e, controlIndex)"
                    />
                  </v-menu>
                  <v-text-field
                    v-if="
                      getActiveFilter &&
                      ['number', 'text'].includes(
                        getActiveFilter.column.type
                      ) &&
                      getActiveFilter.column.predefined &&
                      !getControlComponent(getActiveFilter.column, control)
                    "
                    :id="
                      getActiveFilter
                        ? `filter-${getActiveFilter.column._t_id}`
                        : ''
                    "
                    :key="`control-text-filter-${getActiveFilter.column._t_id}`"
                    :label="control.text"
                    :placeholder="control.text"
                    solo
                    flat
                    :type="getActiveFilter.column.type"
                    :value="control.value"
                    autocomplete="off"
                    @input="
                      (e) => updateMultiValueFilterCriteria(e, controlIndex)
                    "
                  />
                  <component
                    :is="getControlComponent(getActiveFilter.column, control)"
                    v-if="getControlComponent(getActiveFilter.column, control)"
                    :id="`data-table-control-component-${control.id}`"
                    :key="`data-table-control-component-${control.id}`"
                    :control="control"
                    :column="getActiveFilter.column"
                    :filters="filterUtil"
                    :add-filter="addFilter"
                    @make-dirty-request="makeFilterStateDirty"
                  />
                </v-flex>
              </v-layout>
              <v-flex
                v-if="getActiveFilter.column.type === 'date'"
                style="align-self: flex-end"
              >
                <p
                  :style="`
                    margin: 0px;
                    font-size: 12px;
                    width: fit-content;
                    color: ${$cr.theme.gray};
                  `"
                >
                  Timezone:
                  {{
                    currentUser.timeZone || currentUser.company.address.timeZone
                  }}
                </p>
              </v-flex>
            </v-layout>
          </div>
          <v-date-picker
            v-if="
              getActiveFilter &&
              !getActiveFilter.column.predefined &&
              getActiveFilter.column.type === 'date'
            "
            :id="
              getActiveFilter ? `filter-${getActiveFilter.column._t_id}` : ''
            "
            icon="calendar-today"
            :value="getActiveFilter ? getActiveFilter.value : ''"
            editable
            solo
            flat
            no-title
            @input="updateFilterCriteria"
          />
        </div>
      </div>

      <div>
        <template />
      </div>
      <v-data-table
        :items="list"
        :pagination.sync="pagination"
        :hide-actions="hideActions"
        :total-items="total"
        :headers="shownColumns"
        :headers-length="shownColumns.length"
        :item-key="detailKeyId"
        :expand="isDetailed"
        :rows-per-page-items="rowsPerPageItems"
        transparent
      >
        <template #headers="props">
          <tr>
            <th v-if="showMultiSelectActions" style="width: 1%">
              &nbsp;
              <div v-if="enableSelectAll">
                <v-btn
                  v-if="selectAll"
                  :id="`data-table-all-checkbox-selector`"
                  small
                  icon
                  @click="(event) => updateSelectAll()"
                >
                  <v-icon color="primary">check_box</v-icon>
                </v-btn>
                <v-btn
                  v-if="!selectAll"
                  small
                  icon
                  @click="(event) => updateSelectAll()"
                >
                  <v-icon color="primary">check_box_outline_blank</v-icon>
                </v-btn>
              </div>
            </th>
            <th
              v-for="(column, key) in props.headers"
              :id="`data-table-header-column-${
                column.text ? column.text.replace(' ', '-').toLowerCase() : key
              }`"
              :key="`data-table-header-column-${
                column.text ? column.text.replace(' ', '-').toLowerCase() : key
              }`"
              :style="[
                column.shrinkWidth
                  ? ''
                  : `width: ${
                      column.specificWidth ||
                      (showMultiSelectActions
                        ? Number(100 / columns.length + 1).toFixed(0)
                        : Number(100 / columns.length).toFixed(0))
                    }% !important;`,
                showCollapseIcon && key === collapseIconIndex
                  ? `padding-left: 30px !important;`
                  : `padding-left: 0px;`,
              ]"
              :class="
                column.headerClass ? column.headerClass : 'header-default'
              "
              @click="column.sort === true ? initSort(column) : () => true"
            >
              <strong>{{ column.text }}</strong>

              <v-tooltip :key="`tooltip-${key}${column._t_id}`" top>
                <template #activator="{ on }">
                  <span
                    v-if="column.filter"
                    :id="`data-table-header-column-${
                      column.text
                        ? column.text.replace(' ', '-').toLowerCase()
                        : key
                    }-button-search`"
                    @click="
                      (event) => {
                        closableChips[column.prop] = true
                        setFilter(event, column)
                      }
                    "
                    v-on="on"
                  >
                    <CRIcon
                      :width="17"
                      :height="15"
                      view-box="0 0 24 24"
                      color="primary"
                      style="
                        position: relative;
                        margin-bottom: -4px;
                        width: 17px;
                      "
                    >
                      search
                    </CRIcon>
                  </span>
                </template>
                <span>Filter {{ column.text }}</span>
              </v-tooltip>

              <span :key="`data-table-sort-key${currentSort.key}`">
                <CRIcon
                  v-if="
                    currentSort &&
                    currentSort.id === column._t_id &&
                    currentSort.direction === 'asc'
                  "
                  :width="16"
                  :height="16"
                  view-box="0 0 24 24"
                  style="margin-bottom: -4px"
                >
                  arrow_upward
                </CRIcon>
                <CRIcon
                  v-else-if="
                    currentSort &&
                    currentSort.id === column._t_id &&
                    currentSort.direction === 'desc'
                  "
                  :width="16"
                  :height="16"
                  view-box="0 0 24 24"
                  style="margin-bottom: -4px"
                >
                  arrow_downward
                </CRIcon>
                <v-icon v-else small style="width: 16px">fa-fw</v-icon>
              </span>
            </th>
          </tr>
        </template>
        <template #items="props">
          <tr
            :id="`data-table-row-${props.index}`"
            :class="rowClass(props)"
            @click="() => activateRow(props)"
          >
            <td v-if="showMultiSelectActions">
              <v-btn
                v-if="isRowSelected(props)"
                :id="`data-table-row-${props.index}-checkbox-selector`"
                small
                icon
                @click="(event) => selectionHandler(props)"
              >
                <v-icon color="primary">check_box</v-icon>
              </v-btn>
              <v-btn
                v-if="!isRowSelected(props)"
                small
                icon
                @click="(event) => selectionHandler(props)"
              >
                <v-icon color="primary">check_box_outline_blank</v-icon>
              </v-btn>
            </td>
            <td
              v-for="(col, idx) in shownColumns"
              :id="`data-table-row-${props.index}-col-${idx}-button-detail`"
              :key="`data-table-td-${col._t_id}-${idx}-${pagination.page}`"
              :style="col.forceOneLine ? 'white-space: nowrap;' : ''"
              :class="[
                col.dataClass ? col.dataClass : 'data-default',
                col.shrinkWidth ? 'shrink' : '',
              ]"
              @click="
                col.detail === false ? true : handleAction('detail', props)
              "
            >
              <span
                v-if="
                  showCollapseIcon &&
                  idx === collapseIconIndex &&
                  !props.expanded
                "
                @click="handleAction('detail', props)"
              >
                <CRIcon
                  :id="`data-table-row-${props.index}-col-${idx}-button-arrow-down`"
                  style="font-size: 20px; margin-bottom: -6px"
                  material
                >
                  keyboard_arrow_down
                </CRIcon>
              </span>
              <span
                v-else-if="
                  showCollapseIcon &&
                  idx === collapseIconIndex &&
                  props.expanded
                "
                @click="handleAction('detail', props)"
              >
                <CRIcon
                  :id="`data-table-row-${props.index}-col-${idx}-button-arrow-up`"
                  style="font-size: 20px; margin-bottom: -6px"
                  material
                >
                  keyboard_arrow_up
                </CRIcon>
              </span>

              <template v-if="!getColumnComponent(col)">
                {{ columnDisplay(props.item, fullColumnDefinition(col)) }}
              </template>
              <template v-if="getColumnComponent(col)">
                <component
                  :is="getColumnComponent(col)"
                  :id="`data-table-row-${props.index}-col-${idx}`"
                  :key="`data-table-page-${currentPage}-row-${props.index}-col-${idx}`"
                  :handle-action="handleAction"
                  :bubble-action="bubbleAction"
                  :column="fullColumnDefinition(col)"
                  :row="props.item"
                  :row-props="props"
                />
              </template>
            </td>
          </tr>
        </template>
        <br />
        <template #expand="props">
          <component
            :is="detail"
            :id="`data-table-row-${props.index}`"
            :row="props.item"
            :props="props"
            @row-expanded="trackRowExpansion"
            @refresh-query-request="refreshQuery"
          />
        </template>
        <br />
        <br />
        <template v-if="loading" slot="footer">
          <tr style="height: 2px">
            <td :colspan="shownColumns.length">
              <div
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                class="cr-progress-linear"
                style="height: 2px"
              >
                <div class="v-progress-linear__bar">
                  <div
                    class="v-progress-linear__bar__indeterminate v-progress-linear__bar__indeterminate--active"
                  >
                    <div
                      class="v-progress-linear__bar__indeterminate long primary"
                    />
                    <div
                      class="v-progress-linear__bar__indeterminate short primary"
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-layout>
  </v-layout>
</template>

<script>
import op from 'simple-object-path'
import uuid from 'uuid'
import { DateTime } from 'luxon'
import deepPluckRef from 'deep-pluck-ref'
import tables from '@/services/tables'
import { authComputed } from '@/state/helpers'

import * as logger from '@/utils/logger'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { columnDisplay } from '@/utils/columnDisplay'
import { objectDataPaths } from '@/utils/objectDataPaths'
import { saveAs } from 'file-saver'
import { deepClone } from '@/utils/deepClone'

import Draggable from 'vuedraggable'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'DataTable',
  components: {
    Draggable,
  },
  props: {
    initialFilters: { type: Array, default: () => [] },
    ephemeralFilters: { type: Array, default: () => [] },
    addNewEnabled: { type: Boolean },
    addNewButtonLabel: { type: String, default: 'Add New' },
    forceRefresh: { type: Number, default: 0 },
    addNewHandler: { type: Function, default: () => null },
    // eslint-disable-next-line vue/no-boolean-default
    enableFilterRow: { type: Boolean, default: true },
    enableExport: { type: Boolean },
    exportAction: { type: String, default: '' },
    exportServiceAction: { type: Function, default: null },
    exportName: { type: String, default: 'download.csv' },
    exportParams: { type: Object, default: () => {} },
    enableImport: { type: Boolean },
    enableColumnConfig: { type: Boolean },
    enableSavedViews: { type: Boolean },
    enableStatusFilterToggle: { type: Boolean },
    enableSelectableRows: { type: Boolean },
    enableSelectAll: { type: Boolean },
    enableAdditionalActions: { type: Boolean },
    hideActions: { type: Boolean },
    list: { type: Array, default: () => [] },
    columns: { type: Array, default: () => [] },
    perPage: { type: Number, default: 10 },
    currentPage: { type: Number, default: () => 1 },
    total: { type: Number, default: null },
    sort: { type: Function, default: () => null },
    changePage: { type: Function, default: () => null },
    detail: { type: Object, default: () => {} },
    detailKeyId: { type: String, default: '' },
    isDetailed: { type: Boolean },
    showCollapseIcon: { type: Boolean },
    collapseIconIndex: { type: Number, default: 0 },
    addFilter: { type: Function, default: () => null },
    removeFilter: { type: Function, default: () => null },
    shareFilters: { type: Function, default: () => null },
    shareSorts: { type: Function, default: () => null },
    loading: { type: Boolean },
    categories: { type: Array, default: () => [] },
    tableId: { type: String, default: () => undefined },
    isAdmin: { type: Boolean },
    importTable: { type: Function, default: () => null },
    rowClass: { type: Function, default: () => null },
    rowsPerPageItems: {
      type: Array,
      default: () => [...Array(10).keys()].map((x) => (x + 1) * 10),
    },
    resetPage: { type: Boolean },
    calculatedValues: { type: Object, default: () => undefined },
    selectedRows: { type: Array, default: () => [] },
  },
  data() {
    return {
      currentSort: {
        prop: undefined,
        direction: undefined,
      },
      areStatusFiltersShowing: true,
      showFilters: false,
      showColumnConfig: false,
      pagination: {
        rowsPerPage: this.perPage,
        page: this.currentPage,
      },
      filters: [],
      selectedFilter: undefined,
      sorts: sort(),
      filterUtil: filter(),
      shownSelectedColumn: [],
      hiddenSelectedColumn: [],
      shownColumns: [],
      hiddenColumns: [{ text: '' }],
      activeColumn: undefined,
      activeRow: undefined,
      columnDisplay,
      categoryFilters: [],
      selectedCategory: [],
      currentViewSettings: {},
      showClearText: false,
      sheetNameEdit: false,
      sheetColumns: false,
      sheetViewRemove: false,
      sheetViewShare: false,
      savedViews: [],
      currentViewOriginal: undefined,
      removeViewObject: undefined,
      shareViewObject: undefined,
      editViewObject: {},
      closableChips: {},
      favoriteView: {},
      expandedRows: [],
      showMultiSelectActions: false,
    }
  },
  computed: {
    ...authComputed,
    isAnyFilterActive() {
      return (
        this.filters.filter((filter) => !filter.hideOnFilterBar)?.length &&
        this.showFilters
      )
    },
    isAnyHeaderActionActive() {
      return (
        this.enableSavedViews ||
        this.enableExport ||
        this.enableColumnConfig ||
        this.enableImport ||
        this.sheetNameEdit ||
        this.sheetViewRemove ||
        this.sheetViewShare ||
        this.$slots.actionDropDown ||
        this.sheetColumns ||
        this.addNewEnabled ||
        this.enableSelectableRows
      )
    },

    selectAll() {
      return this.selectedRows.length === this.list.length
    },

    getActiveFilter() {
      return this.filters.find((f) => f.column._t_id === this.selectedFilter)
    },
    hiddenColumnsFiltered() {
      return this.hiddenColumns.filter((col) => col._t_id).length > 0
    },
    groupedCategoryFilters() {
      return this.categoryFilters.reduce((map, category) => {
        if (category.group) {
          if (!map[category.group]) {
            map[category.group] = []
          }
          map[category.group].push(category)
        }
        return map
      }, {})
    },
    soloCategoryFilters() {
      return this.categoryFilters.filter((category) => !category.group)
    },
  },
  watch: {
    pagination: {
      handler() {
        this.collapseExpandedRows()
        this.changePage(this.pagination)
      },
    },
    resetPage() {
      this.pagination.page = 1
    },
    categories() {
      this.convertCategories()
    },
    columns() {
      const { columns } = this
      let columnsUserCanView = this.checkColumnPermissions(columns)
      const hidden = columnsUserCanView.filter(
        (column) => column.defaultHidden && !column.filterPlaceholder
      )
      const shown = columnsUserCanView.filter(
        (column) => !column.defaultHidden && !column.filterPlaceholder
      )
      this.hiddenColumns = hidden
      this.sortHiddenColumns()
      this.shownColumns = shown
    },
    initialFilters() {
      this.setInitialFilters()
    },
    forceRefresh(incoming) {
      if (incoming !== 0) {
        this.refreshQuery()
      }
    },
    ephemeralFilters() {
      if (this.ephemeralFilters?.length) {
        this.filterUtil.clear()
        this.rebuildFilters(this.ephemeralFilters)
      }
    },
  },
  async mounted() {
    const { columns } = this
    columns.forEach((c) => (c._t_id = c._t_id || uuid.v4()))
    columns.forEach((c, idx) => (c.pos = idx))
    this.shareFilters(this.filterUtil)
    this.shareSorts(this.sorts)
    let columnsUserCanView = this.checkColumnPermissions(columns)
    const hidden = columnsUserCanView.filter(
      (column) => column.defaultHidden && !column.filterPlaceholder
    )
    const shown = columnsUserCanView.filter(
      (column) => !column.defaultHidden && !column.filterPlaceholder
    )
    this.hiddenColumns = hidden
    this.sortHiddenColumns()
    this.shownColumns = shown
    this.convertCategories()
    await this.fetchSavedViews()
    const favoriteViewData = await tables.favoriteView({
      tableId: this.tableId,
    })
    const favoriteViewId =
      favoriteViewData?.data?.savedViewFavorite?.savedViewId
    this.favoriteView = { favoriteId: favoriteViewId }
    if (this.favoriteView) {
      const reloadedView = this.savedViews.find((savedView) => {
        return savedView._id === this.favoriteView.favoriteId
      })
      this.reloadSavedView(reloadedView)
    } else {
      const defaultSortColumn = columns.find((column) => column.defaultSort)
      if (defaultSortColumn) {
        this.initSort(defaultSortColumn, true)
      }
      if (this.ephemeralFilters?.length) {
        this.rebuildFilters(this.ephemeralFilters)
      }
    }

    EventBus.$on('refresh-query-request', () => {
      this.refreshQuery()
    })
  },
  beforeDestroy() {
    EventBus.$off('refresh-query-request')
  },
  methods: {
    async exportTable() {
      if (
        !this.enableExport ||
        !(this.exportAction || this.exportServiceAction)
      ) {
        return
      }
      const sorts = this.sorts.asQueryParams()
      const filters = this.filterUtil.asQueryParams()
      const params = {
        sorts,
        filters,
        ...this.exportParams,
      }
      this.$emit('update:loading', true)
      try {
        let exportData
        if (this.exportServiceAction) {
          exportData = await this.exportServiceAction(params)
        } else {
          exportData = await this.$store.dispatch(this.exportAction, params)
        }
        await saveAs(exportData.data, this.exportName)
      } finally {
        this.$emit('update:loading', false)
      }
    },
    updateSelectAll() {
      this.toggleSelectAll()
    },
    selectionHandler(row) {
      if (!this.isRowSelected(row)) {
        this.$emit('update:selectedRows', this.selectedRows.concat([row]))
      } else {
        this.$emit(
          'update:selectedRows',
          this.selectedRows.filter((r) => r.index !== row.index)
        )
      }
    },
    sortHiddenColumns() {
      this.hiddenColumns.sort((a, b) => a.text.localeCompare(b.text))
    },
    clearSelectedRows() {
      this.$emit('update:selectedRows', [])
    },
    isRowSelected(row) {
      return !!this.selectedRows.find((r) => r.index === row.index)
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.clearSelectedRows()
      } else {
        this.$emit(
          'update:selectedRows',
          this.list.map((r, i) => ({ item: r, index: i }))
        )
      }
    },

    makeFilterStateDirty() {
      this.currentViewSettings.isDirty = true
    },
    checkColumnPermissions(columns) {
      return columns.filter(
        (item) =>
          !item.permissions ||
          item.permissions.find((permission) => this.hasPermission(permission))
      )
    },
    refreshQuery() {
      this.collapseExpandedRows()
      this.changePage(this.pagination)
    },
    collapseExpandedRows() {
      this.expandedRows.forEach((rowProps) => (rowProps.expanded = false))
    },
    trackRowExpansion(rowProps) {
      this.expandedRows.push(rowProps)
    },
    chipChange(value, filter) {
      if (!value) {
        this.unsetFilter(filter)
      }
    },
    // SORT METHOD
    initSort(column, preventDirty) {
      const sortProp = column.sortProp || column.prop
      if (!preventDirty) {
        this.currentViewSettings.isDirty = true
      }
      this.currentSort.key = uuid.v4()
      if (this.currentSort) {
        if (this.currentSort.prop === sortProp) {
          const { direction } = this.currentSort
          this.currentSort.direction = direction === 'desc' ? 'asc' : 'desc'
          return this.sort(this.currentSort)
        }
      }
      this.currentSort = {
        key: uuid.v4(),
        id: column._t_id,
        prop: sortProp,
        direction: 'desc',
      }
      return this.sort(this.currentSort)
    },
    // END -- SORT METHOD

    // COLUMN DISPLAY METHODS
    toggleColumnConfig(event) {
      event.cancelBubble = true
      this.showColumnConfig = !this.showColumnConfig
    },
    setShownActive(column) {
      this.activeColumn = column
    },
    moveColumn(column, where) {
      if (column.text === '' && !column._t_id) {
        return
      }
      this.currentViewSettings.isDirty = true
      if (where === 'hidden') {
        const index = this.shownColumns.findIndex(
          (c) => c._t_id === column._t_id
        )
        this.shownColumns.splice(index, 1)
        setTimeout(() => {
          if (
            this.hiddenColumns.length === 1 &&
            this.hiddenColumns[0].text === ''
          ) {
            this.hiddenColumns = []
          }
          this.hiddenColumns.push(column)
          this.activeColumn = undefined
        }, 0)
      } else {
        const index = this.hiddenColumns.findIndex(
          (c) => c._t_id === column._t_id
        )
        this.hiddenColumns.splice(index, 1)
        if (!this.hiddenColumns.length) {
          this.hiddenColumns = [{ text: '' }]
        }
        setTimeout(() => {
          this.shownColumns.push(column)
          this.activeColumn = undefined
        }, 0)
      }
    },
    sortActiveColumn(direction) {
      if (!this.activeColumn) {
        return
      }
      this.currentViewSettings.isDirty = true
      const currentIndex = this.shownColumns.findIndex(
        (c) => c._t_id === this.activeColumn._t_id
      )
      const columns = JSON.parse(JSON.stringify(this.shownColumns))
      let newIndex = currentIndex
      if (direction === 'up') {
        newIndex--
      }
      if (direction === 'down') {
        newIndex++
      }
      if (newIndex < 0) {
        newIndex = 0
      }
      if (newIndex > columns.length) {
        newIndex = columns.length
      }
      columns.splice(currentIndex, 1)
      columns.splice(newIndex, 0, this.activeColumn)
      setTimeout(() => {
        this.shownColumns = columns
      }, 0)
    },
    // END -- COLUMN DISPLAY METHODS

    // CORE FILTER METHODS
    async setInitialFilters() {
      this.filterUtil.clear()
      for (const initialFilter of this.initialFilters) {
        await this.setFilter(
          {},
          initialFilter.column,
          initialFilter.hideOnFilterBar
        )
        this.updateFilterCriteria(initialFilter.value)
      }
      this.shareFilters(this.filterUtil)
      this.addFilter()
    },
    async setFilter(event, column, hideOnFilterBar) {
      event.cancelBubble = true
      if (!this.preventDirty) {
        this.currentViewSettings.isDirty = true
      }
      this.selectedFilter = column._t_id
      const exists = this.filterUtil.find({ column })
      if (!exists) {
        const newFilter = { column, hideOnFilterBar }
        this.filters.push(newFilter)
        const activeFilter = this.getActiveFilter
        if (activeFilter.column.method) {
          const grandParent = this.filterUtil.createParent('and')
          const parent = this.filterUtil.createParent('or', grandParent)
          this.filterUtil.add(parent, activeFilter)
        } else {
          this.filterUtil.and(activeFilter).add(activeFilter)
        }
        if (column.recalculate) {
          newFilter.recalculate = column.recalculate
        }
        if (column.predefined && Array.isArray(column.predefined)) {
          const defaultPredefined = column.predefined.find(
            (predefined) => predefined.defaultOnSelection
          )
          if (defaultPredefined) {
            await this.selectPredefined(defaultPredefined)
          }
          if (
            column.predefined.length == 1 &&
            !column.predefined?.[0]?.checkbox
          ) {
            await this.selectPredefined(column.predefined[0])
          }
        }
        if (column.defaultValue) {
          const calculationFunction = this.calculatedValues[column.defaultValue]
          if (typeof calculationFunction === 'function') {
            newFilter.value = await calculationFunction()
          } else {
            newFilter.value = column.defaultValue
          }
          this.addFilter()
        }
      }
      this.showFilters = true
    },
    unsetPeerFilters(filter) {
      const { unset = [] } = filter.column
      unset.forEach((unsetFilterId) => {
        const foundColumn = this.columns.find(
          (column) => column._t_id === unsetFilterId
        )
        if (foundColumn) {
          const foundFilter = this.filterUtil.find({ column: foundColumn })
          if (foundFilter) {
            this.filterUtil.remove(foundFilter)
          }
        }
      })
    },
    unsetFilter(filter) {
      // HANDLE REMOVING EPHEMERAL COLUMN FILTERS CREATED BY FILTER COMPONENTS
      // IDENTIFIED BY STEPPARENT PROP.
      for (const f of this.filterUtil.parents()) {
        for (const c of this.filterUtil.children(f)) {
          if (filter.column._t_id === c.stepParent) {
            this.filterUtil.remove(c)
          }
        }
      }

      this.currentViewSettings.isDirty = true
      this.unsetPeerFilters(filter)
      this.filterUtil.remove(filter)
      setTimeout(() => {
        this.filters = this.filters.filter(
          (f) => f.column._t_id !== filter.column._t_id
        )
        const nextDefaultFilter = this.filters.find((f) => !f.hideOnFilterBar)
        if (nextDefaultFilter && nextDefaultFilter.column) {
          this.setFilter({}, nextDefaultFilter.column)
        }
        this.removeFilter()
      }, 0)
    },
    updateFilterCriteria(filterValue) {
      const activeFilter = this.getActiveFilter
      if (filterValue?.target) {
        activeFilter.value = filterValue?.target?.value
      } else {
        activeFilter.value = filterValue
      }
      this.currentViewSettings.isDirty = true
      this.addFilter()
    },
    renderFilterValue(value, customFilterTabDisplay) {
      if (customFilterTabDisplay) {
        return `: ${customFilterTabDisplay(value)}`
      }
      if (typeof value === 'string') {
        if (value.length > 10) {
          return `: ${value.substring(0, 10)} ...`
        }
        return `: ${value}`
      }
      if (value instanceof Date) {
        return `: ${DateTime.fromISO(value).toLocaleString(
          DateTime.DATE_SHORT
        )}`
      }
    },
    toggleFilterDisplay(e) {
      e.cancelBubble = true
      this.showFilters = !this.showFilters
    },
    showFilterDetail(filter) {
      this.selectedFilter = filter.column._t_id
    },
    // END -- CORE FILTER METHODS

    // PRE-DEFINED AND MULTIVALUE FILTER METHODS
    handleDatePickerInput(event, controlIndex) {
      const timestamp = DateTime.fromFormat(event, 'yyyy-MM-dd')
      if (controlIndex === 0) {
        event = timestamp.startOf('day').toISODate()
      }
      if (controlIndex === 1) {
        const newTimestamp = timestamp.startOf('day').toISODate()
        event = newTimestamp
      }
      this.updateMultiValueFilterCriteria(event, controlIndex)
    },

    updateMultiValueFilterCriteria(filterValue, index) {
      const value =
        filterValue && filterValue.target
          ? filterValue.target.value
          : filterValue
      const activeFilter = this.getActiveFilter
      const { selectedPredefined } = activeFilter
      selectedPredefined.controls[index].value = value
      if (activeFilter.column.type === 'date') {
        selectedPredefined.controls[index].displayValue = DateTime.fromISO(
          value
        ).toFormat('yyyy-MM-dd')
      }
      this.addFilter()
    },
    async selectPredefined(predefined) {
      const filter = this.getActiveFilter
      if (!predefined.id) {
        predefined.id = uuid.v4()
      }
      const currentSelectedPredefinedId = filter?.selectedPredefined?.id
      if (currentSelectedPredefinedId !== predefined.id) {
        this.unsetPeerFilters(filter)
      }
      this.$nextTick(async () => {
        filter.selectedPredefined = predefined
        const valueRefs = deepPluckRef(predefined, ['value'])
        for (const valueRef of valueRefs) {
          if (typeof valueRef !== 'object') {
            continue
          }
          const calculationFunction = this.calculatedValues?.[
            valueRef.recalculate || valueRef.value
          ]
          if (typeof calculationFunction === 'function') {
            valueRef.value = await calculationFunction()
            valueRef.displayValue = DateTime.fromISO(valueRef.value).toFormat(
              'yyyy-MM-dd'
            )
          }
        }
        this.currentViewSettings.isDirty = true
        this.$forceUpdate()
        if (predefined.refreshOnSelect) {
          this.addFilter()
        }
      })
    },
    async selectPredefinedWithValue(column, predefined) {
      const valueRef = predefined?.value
      if (predefined.customEval) {
        const activeFilter = this.getActiveFilter
        predefined.customEval(activeFilter, valueRef)
        this.currentViewSettings.isDirty = true
        this.addFilter()
        return
      }
      if (predefined.filterType) {
        const activeFilter = this.getActiveFilter
        activeFilter.column.filterType = predefined.filterType
      }
      if (valueRef !== undefined) {
        const activeFilter = this.getActiveFilter
        activeFilter.value = valueRef
        this.currentViewSettings.isDirty = true
        this.addFilter()
      }
    },
    // END -- PRE-DEFINED AND MULTIVALUE FILTER METHODS

    // CATEGORY FILTER METHODS
    categoryChipSelected(category, control) {
      const categoryFilter = { column: category }
      const filterMatch = this.filterUtil.find(categoryFilter)
      const selectedPredefinedControls =
        filterMatch?.selectedPredefined?.controls || []
      const exists = this.selectedCategory.find(
        (selected) =>
          selected._t_id === category._t_id &&
          selectedPredefinedControls.find(
            (selectedPredefinedControl) =>
              selectedPredefinedControl.value === control.categoryValue
          )
      )

      return !!exists
    },

    // CATEGORY FILTER METHODS
    categoryAutocompleteSelected(category) {
      const categoryFilter = { column: category }
      const filterMatch = this.filterUtil.find(categoryFilter)
      const selectedPredefinedControls =
        filterMatch?.selectedPredefined?.controls || []
      const foundControl = selectedPredefinedControls.find(
        (selectedPredefinedControl) =>
          selectedPredefinedControl.value ===
          selectedPredefinedControl.categoryValue
      )
      return foundControl
    },

    setCategoryFilter(category, control) {
      if (!category || !control) {
        return
      }
      const hideOnFilterBar = true
      const categoryFilter = { column: category }
      const exists = this.filterUtil.find(categoryFilter)
      this.activeFilter = category._t_id
      this.currentViewSettings.isDirty = true

      if (!exists) {
        this.setFilter({}, category, hideOnFilterBar)
        const activeFilter = this.getActiveFilter
        category.predefined.id = uuid.v4()
        activeFilter.selectedPredefined = category.predefined
      }
      const filterObject = this.filterUtil.find(categoryFilter)

      const selectedPredefinedControls =
        filterObject?.selectedPredefined?.controls || []
      const selectedControl = selectedPredefinedControls.find(
        (selectedPredefinedControl) =>
          selectedPredefinedControl.categoryValue === control.categoryValue
      )
      // Handle isempty option in category filters. Used by Team Classifications filter in QuotesTV and ReservationsTV
      if (selectedControl.categoryValue === 'isempty') {
        selectedControl.filterType = 'isempty'
        selectedControl.value = '1'
      } else if (
        typeof selectedControl.value === 'undefined' ||
        selectedControl.value === null
      ) {
        selectedControl.value = control.categoryValue
      } else {
        selectedControl.value = undefined
      }
      if (
        !this.selectedCategory.find(
          (selected) => selected._t_id === category._t_id
        )
      ) {
        this.selectedCategory.push(category)
      }
      this.addFilter()
    },
    convertCategories() {
      this.categoryFilters = this.categories.map((category) => {
        const categoryFilter = {
          _t_id: category._t_id,
          prop: category.prop,
          text: category.text,
          method: 'and',
          childMethod: category.method,
          predefined: [],
          categoryFilter: true,
          group: category.group,
          autocomplete: category.autocomplete,
        }
        categoryFilter.predefined = {
          id: uuid.v4(),
          text: category.text,
          controlType: category.method === 'or' ? 'radio-tag' : 'tag',
          refreshOnSelect: true,
        }
        categoryFilter.predefined.controls = category.values.map(
          (valueObject) => {
            return {
              id: uuid.v4(),
              text: valueObject.text,
              categoryValue: valueObject.value,
              filterType: valueObject.filterType || 'eq',
              overrideProp: valueObject.overrideProp,
            }
          }
        )
        return categoryFilter
      })
    },
    // END -- CATEGORY FILTER METHODS

    // MISC HELPER METHODS
    getColumnComponent(col) {
      const column = this.columns.find((c) => c._t_id === col._t_id)
      if (column && column.component) {
        return column.component
      }
    },
    getControlComponent(col, control) {
      const controlRefs = deepPluckRef(this.columns, ['id'])
      for (const controlRef of controlRefs) {
        if (controlRef.id === control.id && controlRef.component) {
          return controlRef.component
        }
      }
    },
    activateRow(row) {
      this.activeRow = row.index
    },
    fullColumnDefinition(column) {
      return this.columns.find((c) => c._t_id === column._t_id)
    },
    bubbleAction(action, props, actionProps) {
      this.$emit(action, { props, actionProps })
    },
    handleAction(action, props, actionProps) {
      if (props.item.action && props.item.action !== action && props.expanded) {
        props.expanded = false
      }
      this.$nextTick(() => {
        props.item.action = action
        props.item.actionProps = actionProps
        props.expanded = !props.expanded
      })
    },
    // END -- MISC HELPER METHODS

    async rebuildFilters(savedFilters) {
      const banned = ['column']
      const whiteList = ['value', 'id', 'filterType']
      const categoriesAndColumns = [].concat(this.categoryFilters, this.columns)
      const rebuiltFilters = []
      const isParent = (possibleParent) =>
        !possibleParent.parent || possibleParent.method
      const rebuild = async (source, destination) => {
        const sourcePaths = objectDataPaths(source)

        if (!destination.column) {
          destination = { column: destination }
        }
        Object.keys(source).forEach((key) => {
          if (!banned.includes(key)) {
            destination[key] = source[key]
          }
        })
        for (const sourcePath of sourcePaths) {
          const sourceValue = op(source, sourcePath)
          const sourcePathParts = sourcePath.split('/')
          const sourcePathProp = sourcePathParts.pop()
          if (whiteList.includes(sourcePathProp)) {
            const destinationLocation =
              sourcePathParts.length === 0
                ? destination
                : op(destination, sourcePathParts.join('/'))
            try {
              if (
                destinationLocation.recalculate &&
                sourcePathProp === 'value'
              ) {
                const calculationFunction = this.calculatedValues[
                  destinationLocation.recalculate
                ]
                if (typeof calculationFunction === 'function') {
                  destinationLocation[
                    sourcePathProp
                  ] = await calculationFunction()
                }
              } else {
                destinationLocation[sourcePathProp] = sourceValue
              }
            } catch (e) {
              logger.warn('Attempt to set property value failed.', e)
            }
          }
        }
        return destination
      }
      for (const savedFilter of savedFilters) {
        const savedFilterId = savedFilter?.column?._t_id

        if (isParent(savedFilter)) {
          // always add parents as-is
          rebuiltFilters.push(savedFilter)
        } else {
          const categoryOrColumn = categoriesAndColumns.find(
            (c) => c?._t_id === savedFilterId
          )
          if (categoryOrColumn) {
            const rebuiltCategoryOrColumn = await rebuild(
              savedFilter,
              categoryOrColumn
            )
            rebuiltFilters.push(rebuiltCategoryOrColumn)
          }
        }
      }
      this.filters = rebuiltFilters.filter(
        (rebuiltFilter) => !isParent(rebuiltFilter)
      )
      this.selectedCategory = rebuiltFilters
        .filter((rebuiltFilter) => rebuiltFilter?.column?.categoryFilter)
        .map((rebuiltFilter) => rebuiltFilter.column)

      const nextDefaultFilter = this.filters.find((f) => !f.hideOnFilterBar)

      this.showFilters = true
      this.filterUtil.set(rebuiltFilters)
      this.changePage(this.pagination)
      setTimeout(() => {
        if (nextDefaultFilter) {
          this.setFilter({}, nextDefaultFilter.column)
        }
      }, 0)
    },
    rebuildColumnSettings(view) {
      const {
        shownColumns: shownColumnIds = [],
        hiddenColumns: hiddenColumnIds = [],
      } = view
      const otherShownColumns = this.shownColumns.filter(
        (column) =>
          !shownColumnIds.includes(column._t_id) &&
          !hiddenColumnIds.includes(column._t_id)
      )
      const otherHiddenColumns = this.hiddenColumns.filter(
        (column) =>
          !hiddenColumnIds.includes(column._t_id) &&
          !shownColumnIds.includes(column._t_id)
      )
      const rebuiltShownColumns = []
      const rebuiltHiddenColumns = []
      shownColumnIds.forEach((columnId) => {
        const matchingShownColumn =
          this.shownColumns.find((column) => column._t_id === columnId) ||
          this.hiddenColumns.find((column) => column._t_id === columnId)
        if (matchingShownColumn) {
          rebuiltShownColumns.push(matchingShownColumn)
        }
      })
      hiddenColumnIds.forEach((columnId) => {
        const matchingShownColumn =
          this.hiddenColumns.find((column) => column._t_id === columnId) ||
          this.shownColumns.find((column) => column._t_id === columnId)
        if (matchingShownColumn) {
          rebuiltHiddenColumns.push(matchingShownColumn)
        }
      })
      this.shownColumns = [].concat(rebuiltShownColumns, otherShownColumns)
      this.hiddenColumns = [].concat(rebuiltHiddenColumns, otherHiddenColumns)
      this.sortHiddenColumns()
    },
    async confirmShareView(view) {
      const { _id: savedViewId } = view
      await tables.share(savedViewId)
      await this.fetchSavedViews()
      const reloadedView = this.savedViews.find((savedView) => {
        return savedView._id === savedViewId || savedView.name === name
      })
      if (reloadedView && reloadedView.viewSettings) {
        this.reloadSavedView(reloadedView)
      }
      this.currentViewSettings.isDirty = false
      this.sheetNameEdit = false
      this.sheetViewShare = false
    },
    async saveViewSettings(view) {
      let params = view
      params = Object.assign({}, { tableId: this.tableId }, params)
      params.pageSize = this.pagination.rowsPerPage
      params.sort = this.currentSort
      params.hiddenColumns = this.hiddenColumns.map((column) => column._t_id)
      params.shownColumns = this.shownColumns.map((column) => column._t_id)
      params.viewSettings = this.filterUtil.get()
      const { _id: savedViewId, name } = view
      this.currentViewSettings = params
      params.savedViewId = savedViewId
      if (savedViewId) {
        await tables.updateView(params).catch(logger.error)
      } else {
        await tables.addView(params).catch(logger.error)
      }
      await this.fetchSavedViews()
      const reloadedView = this.savedViews.find((savedView) => {
        return savedView._id === savedViewId || savedView.name === name
      })
      if (reloadedView && reloadedView.viewSettings) {
        this.reloadSavedView(reloadedView)
      }
      this.currentViewSettings.isDirty = false
      this.sheetNameEdit = false
      this.sheetViewShare = false
    },
    reloadSavedView(reloadedView) {
      if (!reloadedView) {
        return
      }
      this.currentViewSettings = reloadedView
      this.preventDirty = true
      this.currentSort = reloadedView.sort
      if (reloadedView.sort) {
        this.sorts.add(reloadedView.sort)
      }
      this.pagination.rowsPerPage = reloadedView.pageSize
      this.rebuildColumnSettings(reloadedView)
      this.rebuildFilters(reloadedView.viewSettings)
      setTimeout(() => {
        this.preventDirty = false
      }, 1500)
    },
    loadSavedView(view) {
      this.currentViewOriginal = view
      const clone = JSON.parse(JSON.stringify(view))
      this.currentViewSettings = { ...clone }
      this.sheetNameEdit = false
      if (clone.viewSettings) {
        this.preventDirty = true
        logger.warn(clone.viewSettings)
        this.pagination.rowsPerPage = clone.pageSize
        this.currentSort = clone.sort
        if (clone.sort) {
          this.sorts.add(clone.sort)
        }
        this.rebuildColumnSettings(clone)
        this.rebuildFilters(clone.viewSettings)
        setTimeout(() => {
          this.preventDirty = false
        }, 1500)
      }
    },
    reloadCurrentView() {
      if (!this.currentViewOriginal) {
        this.sheetNameEdit = false
        return
      }
      this.loadSavedView(this.currentViewOriginal)
    },
    clearView() {
      this.currentViewOriginal = undefined
      this.filters = []
      this.filterUtil.clear()
      this.sheetNameEdit = false
      this.currentViewSettings = { tableId: this.tableId }
      this.removeFilter()
    },
    createNewView() {
      this.currentViewSettings = { tableId: this.tableId }
      this.editViewObject = this.currentViewSettings
      this.sheetNameEdit = true
    },
    removeView(view) {
      this.removeViewObject = view
      this.sheetViewRemove = true
    },
    async setFavorite(view) {
      const params = { favoriteId: view._id, tableId: this.tableId }
      await tables.setFavoriteView(params)
      await this.fetchSavedViews()
      const favoriteViewData = await tables.favoriteView({
        tableId: this.tableId,
      })
      const favoriteViewId =
        favoriteViewData?.data?.savedViewFavorite?.savedViewId
      this.favoriteView = { favoriteId: favoriteViewId }
    },
    shareView(view) {
      this.shareViewObject = view
      this.sheetViewShare = true
    },
    confirmRemove(view) {
      tables
        .removeView(view._id)
        .then(async (resp) => {
          await this.fetchSavedViews()
          this.currentViewSettings.isDirty = false
          this.sheetViewRemove = false
          logger.warn('remove view response', resp)
        })
        .catch(logger.error)
    },
    editViewName(view) {
      this.editViewObject = view
      this.sheetNameEdit = true
    },
    beginMultiSelect() {
      this.showMultiSelectActions = true
    },
    endMultiSelect() {
      this.showMultiSelectActions = false
      this.clearSelectedRows()
    },
    async fetchSavedViews() {
      const tableViews = await tables.tableViews(this.tableId)
      this.savedViews = tableViews.data?.savedViews?.map((v) => ({
        ...v,
        _id: v.savedViewId,
        ...JSON.parse(v.viewSettings),
      }))
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep table td,
::v-deep table th {
  vertical-align: middle;
}
</style>

<style lang="scss">
.v-chip {
  cursor: pointer;

  .v-chip__content {
    cursor: pointer;
  }
}

.filter-trigger {
  text-align: left;
}

.selected-tag-container {
  padding: 6px;
  background-color: $blue-pale;
  border-radius: 4px;
}

.unselected-tag-container {
  padding-top: 6px;
  padding-right: 6px;
  padding-left: 6px;
}

.filter-tags {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.filter-panel {
  padding: 10px;
  background-color: $gray-lighter;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.multiselect {
  padding: 10px;
  margin-right: 20px;
  border: 1px solid black;
  border-radius: 4px;
  max-height: 75vh;
  overflow-y: auto;
}

.ms-item {
  cursor: pointer;
  border: 1px solid $white;
}

.ms-item:hover {
  background-color: $blue-pale;
  border: 1px dotted $black;
  border-radius: 2px;
}

.ms-active {
  background-color: $blue-pale;
  border: 1px dotted $black;
  border-radius: 2px;
}

.active-predefined {
  font-weight: bold;
  border: 1px dotted $black;
}

.active-row {
  background-color: $secondary;
}

table.v-table thead {
  > tr {
    height: 32px;

    > th {
      padding: 0 6px;
    }
  }
}

.next-to-parent {
  position: absolute;
  top: 18px;
  left: 34px;
}

.parent-star {
  position: relative;
}

.cr-progress-bar {
  position: relative;
  height: 2px;
}

.v-list__tile__action {
  margin-top: 10px;
}

.status-filters {
  margin-bottom: 14px;
}

.status-filter-select {
  padding: 5px;
  margin-right: 10px;
  text-align: center;
  background-color: $gray-lighter;
  border-radius: 5px;

  &:last-child {
    margin-right: 0;
  }

  h3 {
    font-size: 16px;
    line-height: 1.5em;
  }
}

.bottom-layout {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 15px 0;
}

.filter-control {
  margin-right: 40px;
}

.filter-control:last-child {
  margin-right: 0;
}

.pull-to-top {
  min-height: 48px;
  margin-top: -54px;
  margin-bottom: 24px;
  margin-right: -8px;
}

.padded-menu-item {
  padding-right: 6px;
  padding-left: 10px;

  span {
    margin-left: 4px;
  }
}

.v-tabs__slider.accent {
  background-color: $primary !important;
  border-color: $primary !important;
}

.header-default {
  text-align: left;
}

.header-center {
  text-align: center;
}

.data-default {
  text-align: left;
}

.data-center {
  text-align: center;
}

table.v-table thead td:not(:nth-child(1)),
table.v-table tbody td:not(:nth-child(1)),
table.v-table thead th:not(:nth-child(1)),
table.v-table tbody th:not(:nth-child(1)),
table.v-table thead td:first-child,
table.v-table tbody td:first-child,
table.v-table thead th:first-child,
table.v-table tbody th:first-child {
  padding: 0 6px !important;
}

table.v-table tbody tr td {
  &.shrink {
    width: 1% !important;
    white-space: nowrap !important;
  }
}

// Table row hover
.theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
  background-color: $secondary;
}
</style>
