import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { SavedViewFavoriteResult, SavedViewResult } from '@/models/TableView'
import { ApiResult } from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  tableViews(tableId: number): Promise<AxiosResponse<SavedViewResult>> {
    const host = baseUrl()
    const url = `https://${host}/savedViews/${tableId}`
    return httpService.get(url)
  },
  addView(params: { tableId: number, name: string }): Promise<AxiosResponse<ApiResult>> {
    const { tableId, name, ...body } = params
    const host = baseUrl()
    const payload = {
      tableId,
      name,
      viewSettings: JSON.stringify(body),
    }
    const url = `https://${host}/savedViews`
    return httpService.post(url, payload)
  },
  updateView(params: { tableId: number, name: string, savedViewId: number }): Promise<AxiosResponse<ApiResult>> {
    const { tableId, name, savedViewId, ...body } = params
    const host = baseUrl()
    const payload = {
      tableId,
      name,
      viewSettings: JSON.stringify(body),
    }
    const url = `https://${host}/savedViews/${savedViewId}`
    return httpService.put(url, payload)
  },
  share(savedViewId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/savedViews/share/${savedViewId}`
    return httpService.post(url, null)
  },
  removeView(viewId: number): Promise<AxiosResponse<ApiResult>> {
    const host = baseUrl()
    const url = `https://${host}/savedViews/${viewId}`
    return httpService.delete(url)
  },
  favoriteView(params: { tableId: number}): Promise<AxiosResponse<SavedViewFavoriteResult>> {
    const { tableId } = params
    const host = baseUrl()
    const url = `https://${host}/savedViewFavorites/${tableId}`
    return httpService.get(url)
  },
  setFavoriteView(params: { tableId: number, favoriteId: number }): Promise<AxiosResponse<ApiResult>> {
    const { tableId, favoriteId } = params
    const host = baseUrl()
    const url = `https://${host}/savedViewFavorites/${tableId}/${favoriteId}`
    return httpService.post(url, null)
  },
}
